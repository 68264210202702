@import './_styles/variables.scss';
@import './_styles/scrollbars.scss';
@import './_styles/markdown-frame.scss';

.App {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-title-section {
  height: 48px;
  min-height: 48px;
  border-bottom: 1px solid $lightestGrey;
}

.cancel-button {
	background: $lightestGrey;
	border-radius: 2px;
  border: none;

	&:hover {
		background: $hoverGrey;
	}
}

.company-logo-large {
  max-height: 120px;
  //max-width: 200px;
}

.company-logo-small {
  max-height: 20px;
}

.page-base {
  height: calc(100vh - 40px);
  overflow: auto;
  background-color: rgb(250, 249, 248);
}

.title {
  font-weight: 600;
}

.title-small {
  font-weight: 600;
  font-size: 26px;
}

.title-xsmall {
  font-weight: 600;
  font-size: 20px;
}

.attachment-area {
  display: flex;
  flex-direction: column;
  border: 1px dashed #d1d1d1;
  border-radius: 4px;
  height: 130px;

  .file-selector {
      color: $highlight;
      cursor: pointer;
  }
}

.attachment-area {
  display: flex;
  flex-direction: column;
  border: 1px dashed #d1d1d1;
  border-radius: 4px;
  height: 130px;

  .file-selector {
      color: $highlight;
      cursor: pointer;
  }
}

.attachment {
  height: 32px;
  display: flex;
  align-items: center;
  border: 1px solid $lightGrey;
  margin: 8px 0px 0px 0px;
  padding: 10px;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    cursor: default;
  }
}

.fui-Alert__icon {
	svg {
		vertical-align: baseline;
	}
}

.fui-Button {
  padding: 8px 12px !important;
}

body {
  font-size: 16px;
  button {
    font-size: 16px;
  }
}

.noselect {
	cursor: default !important;
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
	   -khtml-user-select: none; /* Konqueror HTML */
		 -moz-user-select: none; /* Old versions of Firefox */
		  -ms-user-select: none; /* Internet Explorer/Edge */
			  user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}
