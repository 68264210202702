@import '../../_styles/variables.scss';

.code-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	margin: 0 auto;

	span {
		font-size: 20px;
		font-weight: 600;
	}
}